@import "../../index.scss";

.chat-body {
	align-items: flex-start;

	@include flex;
	width: 100%;
	height: 100%;

	border: 2px solid $primary;
	border-bottom: none;
	background-color: $lightwhite;
	transition: all 0.25s;

	.body-wrap {
		overflow-y: scroll;
		position: relative;
		width: 100%;
		min-width: 100%;
		height: 100%;
		min-height: 450px;
	}

	@media (max-width: 500px) {
		font-size: 14px;
		max-width: 100vw;
		border: 2px solid transparent;
		.body-wrap {
			min-width: 100%;
		}
	}

	::-webkit-scrollbar {
		position: relative;
		right: 0px;
		top: 100px;
		width: 0.1px !important;
		height: 0;
	}
}
