@import "../index.scss";

.capstone {
	@include flex;
	flex-direction: column;
	text-align: center;
	position: relative;
	overflow: hidden;
	width: 100vw;
	.introduction {
		* {
			margin: 0;
		}
		@include flex;
		flex-direction: column;
		width: 100%;
		margin-top: 100px;

		h1,
		p {
			margin-bottom: 20px;
		}
	}
}
