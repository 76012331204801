@import "../index.scss";

.submissions {
	margin-top: 20px;
	width: min(90%, 400px);
	.title {
		cursor: pointer;
		user-select: none;
		font-size: 2rem;
		@include flex;
		width: 100px;
		margin: 0 auto;
		margin-top: 0;
		border: 1px solid transparent;
		transition: all 0.2s;
		@media (min-width: 500px) {
			&:hover {
				color: $primary;
			}
		}
		&:active {
			transition: all 0.2s;
			.btn {
				transform: scale(0.95);
			}
		}
	}
	.sub-wrap {
		text-align: left;
		overflow-y: scroll;
		width: 100%;
		max-height: 400px;
		height: 100%;
		margin-top: 40px;
		padding: 0 20px;
		border-radius: 10px;
		border: 1px solid rgb(230, 230, 230);
		background-color: rgb(245, 245, 245);

		.sub {
			font-size: 0.9rem;
			cursor: default;
			@include flex;
			align-items: flex-start;
			flex-direction: column;
			width: 100%;
			margin: 5px 0;
			padding: 10px;
			border-radius: 5px;
			border-bottom: 2px solid rgb(240, 240, 240);
			background-color: rgb(250, 250, 250);

			&:last-child {
				margin-bottom: 20px;
			}

			.header {
				@include flex;
				justify-content: space-between;
				width: 100%;
				margin-bottom: 10px;
				.date,
				.client {
					white-space: nowrap;
					color: rgb(170, 170, 170);
				}
				.client {
					color: $secondary;
				}
			}
		}
	}
}
