@import "../../../index.scss";

.greeting {
	form {
		@include flex;
		width: 100%;

		input {
			margin: 0 auto;
			width: 100%;
		}

		@media (max-width: 500px) {
			margin-top: 20px;
		}
	}
}
