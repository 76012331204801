@import "../../../index.scss";

.animated-content {
	position: absolute;
	top: 0;
	width: 100%;
	padding: 0 20px;

	.greeting,
	.navigation,
	.faq,
	.rating,
	.feedback {
		width: 100%;
	}
}
