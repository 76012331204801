@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap");

::-webkit-scrollbar {
	position: relative;
	right: 0px;
	top: 100px;
	width: 5px !important;
	height: 0;
}
::-webkit-scrollbar-track {
	background-color: darken(white, 25%);
}
::-webkit-scrollbar-thumb {
	background-color: darken(white, 60%);
	border-radius: 5px;
}

.scroller {
	scrollbar-color: #ffffff transparent;
	scrollbar-width: thin;
}

:root {
	--ph: 250;
	--ps: 75%;
	--pl: 30%;
}

$primary: hsl(var(--ph), var(--ps), var(--pl));
$secondary: hsl(var(--ph), var(--ps), 40%);
$offwhite: hsl(0, 0%, 95%);
$lightwhite: hsl(0, 0%, 90%);
$shadow: -2px 2px 6px -5px black;

@mixin flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Quicksand", sans-serif;
	overflow: hidden;
}
input {
	border: none;
	border: 1px solid hsl(0, 0%, 10%);
}
input,
textarea {
	width: 80%;
	padding: 10px;
	border-radius: 7px;
}

.nav-btn-cont {
	@include flex;
	flex-direction: column;
	width: 100%;
	margin-top: 40px;
}
.faded-underline {
	width: 100%;
	text-align: left;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	border-style: solid;
	border-image-slice: 1;
	border-width: 2px;
	border-image-source: linear-gradient(
		to right,
		$secondary,
		#00000000
	);
	border-left: 0px;
	border-top: 0px;
}

@media (max-width: 500px) {
	input,
	textarea {
		font-size: 16px;
		width: 100%;
		max-width: 90vw !important;
	}
}
