@import "../../../index.scss";

.feedback {
	.textarea-wrap {
		textarea {
			resize: vertical;
			width: 100%;
			min-height: 50px;
			max-height: 150px;
			box-shadow: $shadow;
		}
	}
}
