@import "../../../index.scss";

.navigation {
	@include flex;
	flex-direction: column;

	.chat-title {
		margin-bottom: 0;
	}
	.closing-msg {
		text-align: center;
		padding-top: 20px;
		width: 100%;
	}
}
