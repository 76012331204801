@import "../../index.scss";

.chat-head {
	cursor: pointer;
	user-select: none;

	@include flex;
	justify-content: space-between;

	width: 100%;
	height: 100%;
	min-height: 50px;
	max-height: 50px;
	padding: 0 10px;

	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border: 2px solid $primary;
	border-bottom: none;
	color: white;
	background-color: $primary;

	.hidden {
		@include flex;
		width: 100%;
	}
	.visible {
		padding: 0 5px;
	}
	.identity {
		color: hsl(var(--ph), var(--ps), 70%);
	}
}
