@import "../../index.scss";

.chat-window {
	width: 100%;
	max-width: 350px;
	@media (max-width: 500px) {
		font-size: 14px;
		max-width: 100vw;
	}
}
