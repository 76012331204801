@import "../../../index.scss";

.chat-button {
	all: unset;
	cursor: pointer;
	user-select: none;
	text-align: center;
	width: 100%;
	max-width: 100%;

	padding: 10px 0;
	margin: 5px;
	border-radius: 5px;
	color: white;
	box-shadow: $shadow;
	background-color: $secondary;

	transition: all 0.2s;
	&:disabled {
		opacity: 0.25;
		pointer-events: none;
	}
	&:active {
		background-color: $primary;
		transform: scale(0.95);
	}
}

.short {
	max-width: 100px;
}
