@import "../../../index.scss";

.rating {
	.star-wrap {
		@include flex;

		.star {
			cursor: pointer;
			font-size: 2rem;
			margin: 0 5px;
			svg {
				path {
					box-shadow: $shadow;
				}
			}
		}
		color: $primary;
		.full-star {
			color: $secondary;
		}
		.null-star {
			color: $primary;
		}
	}
}
