@import "../../../index.scss";
.faq {
	padding-bottom: 50px;
	.faq-wrap {
		.faq-type {
			.subtitle {
				user-select: none;
				text-align: left;
				width: 100%;
			}
			.question {
				user-select: none;
				cursor: pointer;
				width: 100%;
				padding: 10px;
				padding-left: 10px;
				margin-bottom: 5px;
				border-radius: 5px;
				transition: all 0.2s;
				.response {
					font-size: 0.95rem;
					padding: 10px;
					margin: 0;
					border-radius: 10px;
					border-width: 2px;
					border-style: solid;
					border-image-slice: 1;
					border-image-source: linear-gradient(
						to right,
						rgb(200, 200, 200),
						#00000000
					);
					border-bottom: 0px;
					border-left: 0px;
				}

				&:active {
					transform: scale(0.95);
					box-shadow: $shadow;
					background-color: $offwhite;
				}

				@media (min-width: 500px) {
					&:hover {
						box-shadow: $shadow;
						background-color: $offwhite;
					}
				}
			}

			.active {
				border-radius: 5px;
				box-shadow: $shadow;
				background-color: $offwhite;
				.question-text {
					margin-top: 5px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
